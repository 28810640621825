<template>
    <div>
        <infoHeader :content_name="'工单创建指派'" />

        <h3>工单信息：</h3>

        <el-form ref="form" label-width="120px" :label-position="'left'">
            <!-- 自动生成工单编号 -->
            <el-form-item label="工单编号：">
                <el-input
                    v-model="order_id"
                    style="width: 50%"
                    disabled
                ></el-input>
            </el-form-item>
            <el-form-item label="工单名称：">
                <el-input v-model="order_name" style="width: 50%"></el-input>
            </el-form-item>

            <el-form-item label="工单类型选择：">
                <el-select
                    v-model="ruleForm.Formtype"
                    placeholder="请选择"
                    style="width: 50%"
                >
                    <el-option
                        v-for="item in ruleForm.type_option"
                        :key="item.id"
                        :label="item.type_name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="工单优先级：">
                <el-select
                    v-model="ruleForm.priority"
                    placeholder="请选择"
                    style="width: 50%"
                >
                    <el-option
                        v-for="item in ruleForm.priorit_option"
                        :key="item.id"
                        :label="item.priority_name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="指派负责人：" required>
                <el-button v-if="!selectItem" @click="showPopSelector"
                    >选择负责人</el-button
                >
                <div v-if="selectItem">
                    <el-descriptions :column="2" direction="vertical" border>
                        <el-descriptions-item label="维修人员ID">{{
                            selectItem.technician_id
                        }}</el-descriptions-item>
                        <el-descriptions-item label="维修人员姓名">{{
                            selectItem.name
                        }}</el-descriptions-item>
                        <el-descriptions-item label="所属部门">{{
                            selectItem.partment
                        }}</el-descriptions-item>
                    </el-descriptions>
                    <el-button style="margin-top: 10px" @click="showPopSelector"
                        >重新选择负责人</el-button
                    >
                </div>
            </el-form-item>

            <el-form-item label="工单相关文件:">
                <el-button v-if="!selectFile" @click="showFileSelector"
                    >选择相关文件</el-button
                >
                <div></div>
            </el-form-item>

            <el-form-item label="">
                <el-button type="primary" @click="handleAssignTech"
                    >确认无误，指派工单</el-button
                >
            </el-form-item>
        </el-form>

        <el-divider></el-divider>

        <!-- 弹窗组件 -->
        <CommonPopSelectorVue
            title="运维人员名单"
            :visible.sync="popSelectorVisible"
            :table-data="mockTableData"
            :table-columns="tableColumns"
            :table-button="tableButton"
            @selectItem="handleSelectItem"
        />
        <!--选择工单文件  -->
        <CommonPopSelectorVue
            title="工单相关文件"
            :visible.sync="fileSelectorVisible"
            :table-data="mockTableData"
            :table-columns="tableColumns"
            :table-button="tableButton"
            @selectItem="handleSelectItem"
        />
    </div>
</template>

<script>
// 载入接口
import { work_order_assign_technician } from '@/api/partner/garageManage/work_order/work_order.js';

// 载入方法
// import { 方法名 } from "@/utils/js文件名";

// 载入组件

// 弹窗选择器
import CommonPopSelectorVue from '@/components/shibo_Common/CommonPopSelector.vue';
export default {
    name: 'start',
    data() {
        return {
            order_id: '0101000101',
            order_name: null,
            popSelectorVisible: false,
            fileSelectorVisible: false,
            selectItem: null,
            selectFile: null,
            ticket_number: null,
            // mockdata :
            ruleForm: {
                name: '',
                region: '',
                date1: null,
                date2: null,
                type: null,
                type_option: [
                    { id: '1', type_name: '硬件' },
                    { id: '2', type_name: '软件' }
                ],
                priority: null,
                priorit_option: [
                    { id: '1', priority_name: '紧急' },
                    { id: '2', priority_name: '高级' },
                    { id: '3', priority_name: '中级' },
                    { id: '4', priority_name: '低级' }
                ],
                resource: '',
                desc: ''
            },
            mockTableData: [
                { name: '梁苏皖', technician_id: 1, partment: '运维部' },
                { name: '刘德华', technician_id: 2, partment: '运维部' },
                { name: '胡正东', technician_id: 3, partment: '  运维部' }
            ],
            tableColumns: [
                // 动态表格列配置
                { prop: 'name', label: '姓名' },
                { prop: 'id', label: '编号' },
                { prop: 'partment', label: '部门' }
                // 其他列配置项
            ],
            tableButton: [{ label: '选择' }]
        };
    },
    components: { CommonPopSelectorVue },
    created() {
        // this.initOption();
        this.ticket_number = this.$route.query.ticket_number;
    },
    methods: {
        initOption() {},
        showPopSelector() {
            this.popSelectorVisible = true;
        },
        showFileSelector() {
            this.fileSelectorVisible = true;
        },
        handleSelectItem(item) {
            console.log('选中的项目: ', item);
            this.selectItem = item;
        },
        handleAssignTech() {
            if (this.selectItem == null) {
                this.$message.error('请选择指派工单负责人');
            } else {
                let payload = {
                    ticket_number: this.ticket_number,
                    technician_id: this.selectItem.technician_id
                };
                console.log(payload);
                work_order_assign_technician(payload).then(res => {
                    if (res.code == 200) {
                        console.log(123);
                        this.$router.push({
                            name: 'work_Processing',
                        });
                    }
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.workorder-wrapper {
    display: flex;
    justify-content: space-between;
}
</style>
