import request from '@/utils/request';
//合作方
export function work_order_query(data) {
    /**
     * 工单列表查询
     * @param {number} querypage 要获取的页数
     * @param {number} pagesize 每页返回多少条
     * @param {number} order_type 工单类型
     * @param {number} priority
     * @param {number} status
     */
    return request({
        url: '/platform_api/partner_api/workorder/query',
        method: 'post',
        data: data
    });
}

export function work_order_create(data) {
    /**
     * 工单详情查询
     * @param {number} ticket_number //工单ID
     */
    return request({
        url: '/platform_api/manage_api/error_report/create_workorder',
        method: 'post',
        data: data
    });
}

export function work_order_update(data) {
    /**
     * 工单详情更新
     * @param {number} ticket_number //工单ID
     * * @param {number} priority //工单等级
     */
    return request({
        url: '/platform_api/manage_api/workorder/update',
        method: 'post',
        data: data
    });
}

export function work_order_get(data) {
    /**
     * 工单详情查询
     * @param {number} ticket_number //工单ID
     */
    return request({
        url: '/platform_api/partner_api/workorder/info',
        method: 'post',
        data: data
    });
}

export function work_order_assign_technician(data) {
    /**
     * 工单指派技术员
     * @param {number} ticket_number //工单ID
     * @param {number} technician_id //技术员账户ID
     */
    return request({
        url: '/platform_api/manage_api/workorder/assign_technician',
        method: 'post',
        data: data
    });
}

export function work_order_communicate_content(data) {
    /**
     * 获取工单更新内容
     * @param {number} ticket_number //工单ID
     */
    return request({
        url: '/platform_api/partner_api/workorder/communicate_content',
        method: 'post',
        data: data
    });
}

export function work_order_sumbit_reply(data) {
    /**
     * 工单回复接口
     * @param {number} ticket_number // 工单ID
     * * @param {string} content // 回复内容
     */
    return request({
        url: '/platform_api/partner_api/workorder/sumbit_reply',
        method: 'post',
        data: data
    });
}